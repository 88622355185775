import { useEffect, useMemo } from "react";
import { 
  SubmitHandler, FieldValues, Control, Path,
  useFormState, UseFormHandleSubmit, UseFormGetValues,
  UseFormReset,
  useWatch, Controller, ControllerProps, PathValue,
  FieldPath,
} from 'react-hook-form';
import { memoize, debounce as lodashDebounce } from 'lodash-es';
import { FormHelperText, FormLabel, FormControl } from "@mui/material";


function memoizedDebounce <T>(func: (args: T) => void, wait = 0, resolver: (args: T) => string) {
  const mem = memoize(function(arg) {
    return lodashDebounce(func, wait);
  }, resolver);

  return function(arg: T) {
    mem(arg)(arg);
  };
}

type UseAutoSubmitArgs<T extends FieldValues> = {
  control: Control<T>,
  handleSubmit: UseFormHandleSubmit<T>,
  getValues: UseFormGetValues<T>,
  reset: UseFormReset<T>,
  memoizedHandleSubmit: SubmitHandler<T>,
  fields: Path<T>[],
  options?: { [key: string]: { debounce: number } },
  shouldRun?: boolean
};


// NOTE: Debouncing will not work correctly if the submit handler is not memoized
export const useAutoSubmit = <T extends FieldValues>({
  control, handleSubmit, getValues, reset, memoizedHandleSubmit,
  fields, options, shouldRun = true
}: UseAutoSubmitArgs<T>): void => {

  const {isDirty, dirtyFields} = useFormState({control, name: fields});

  const debouncedSubmits = useMemo(() => ( 
    Object.entries(options || {})
      .map(([field, { debounce }]) => ({
        [field]: memoizedDebounce(
          memoizedHandleSubmit, debounce, (data: T) => Object.keys(data).join('-')
        )
      })).reduce((a, b) => ({ ...a, ...b }), {})
      // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [memoizedHandleSubmit]);

  const formFieldsStr = fields?.join(',');
  const formOptionsStr = JSON.stringify(options);
  const debouncedSubmitsStr = JSON.stringify(debouncedSubmits);
  
  useEffect(() => {
    if (shouldRun && isDirty && dirtyFields) {
      const fieldsToSubmit = (fields || Object.keys(dirtyFields) as (keyof T)[])
        .filter(field => field in dirtyFields && (!options || !options[field as string]?.debounce))
        || [];
      const debouncedFieldsToSubmit = (fields || Object.keys(dirtyFields) as (keyof T)[])
        ?.filter(field => field in dirtyFields && options && options[field as string]?.debounce) || [];
      if (fieldsToSubmit?.length) {
        handleSubmit((data) => {
          Object.keys(data).forEach((dataField) => {
            const typedDataField = dataField as Path<T>;
            if (!(fieldsToSubmit.includes(typedDataField))) {
              delete (data as T)[dataField as keyof T];
            }
          });
          memoizedHandleSubmit(data);
        })();
      }
      if (debouncedFieldsToSubmit?.length && options) {
        handleSubmit((data) => {
          debouncedFieldsToSubmit.forEach(field => {
            //   if (originalData[field] !== data[field]) {
            Object.keys(data).forEach(dataField => {
              if (dataField !== field) {
                delete (data as T)[dataField as keyof T]; 
              }
            });
            debouncedSubmits[field as string](data);
          });
        })();
      }
      reset(getValues() as T);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, dirtyFields, formFieldsStr, formOptionsStr, memoizedHandleSubmit, debouncedSubmitsStr]);
};

// type DependentControllerProps<T extends FieldValues> = Omit<ControllerProps<T>, 'render'> & {
//   dependencies: Path<T>[];
//   render: (p: Parameters<ControllerProps<T>['render']>[0] & { deps: PathValue<T, Path<T>>[] })
//   => ReturnType<ControllerProps<T>['render']>;
// };

export const DependentController = <T extends FieldValues, U extends FieldPath<T>>({
  dependencies, ...props
}: Omit<ControllerProps<T, U>, 'render'> & {
  dependencies: Path<T>[];
  render: (p: Parameters<ControllerProps<T, U>['render']>[0] & { deps: PathValue<T, Path<T>>[] })
  => ReturnType<ControllerProps<T, U>['render']>;
}) => {
  const deps = useWatch({ control: props.control, name: dependencies });

  return (
    <Controller
      {...props}
      render={(arg) => {
        return props.render({ ...arg, deps });
      }}
    />
  );
};

// Convenience Form Controller that has built in labels, error text and optionally uses DependentController
// if you give it a `dependencies` prop. Supports null return value from render function which will
// hide the entire element
export const FormController = <T extends FieldValues, U extends FieldPath<T>>({
  control, name, label, rules, render, dependencies
}: {
  control: ControllerProps<T, U>['control'];
  name: ControllerProps<T, U>['name'];
  label: string;
  // render: ControllerProps<T, U>['render'];
  render: (p: Parameters<ControllerProps<T, U>['render']>[0] & { deps: PathValue<T, Path<T>>[] })
  => ReturnType<ControllerProps<T, U>['render']> | null;
  rules?: ControllerProps<T, U>['rules'];
  dependencies?: Path<T>[];
}) =>  (
  dependencies ? (
    <DependentController
      name={name} rules={rules} control={control} dependencies={dependencies}
      render={(args) => {
        const el = render(args);
        return el ? (
          <FormControl sx={{ width: '100%' }}>
            <FormLabel>{label}</FormLabel>
            {el}
            <FormHelperText error>{args.fieldState.error?.message || args.fieldState.error?.type || ''}</FormHelperText>
          </FormControl>
        ) : <></>;
      }}
    />
  ) : (
    <Controller
      name={name} rules={rules} control={control}
      render={(args) => {
        const el = render({ ...args, deps: [] });
        return el ? (
          <FormControl sx={{ width: '100%' }}>
            <FormLabel>{label}</FormLabel>
            {el}
            <FormHelperText error>{args.fieldState.error?.message || args.fieldState.error?.type || ''}</FormHelperText>
          </FormControl>
        ) : <></>;
      }}
    />
  )
);
